import React from 'react';

const ArrowDownIcon = (props)=> {
    return (
        <svg className="o-icon" style={{width: props.iconWidth, height: props.iconHeight}} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 10.001 11.993" enableBackground="new 0 0 10.001 11.993">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.695,6.306c-0.408-0.408-1.07-0.408-1.478,0L6.001,8.523V1c0-0.552-0.448-1-1-1
            c-0.552,0-1,0.448-1,1v7.523L1.784,6.306c-0.408-0.408-1.07-0.408-1.478,0c-0.408,0.408-0.408,1.069,0,1.478l3.911,3.911
            c0.216,0.216,0.501,0.31,0.784,0.298c0.282,0.012,0.568-0.082,0.784-0.298l3.911-3.911C10.103,7.376,10.103,6.714,9.695,6.306z"/>
        </svg>

    );
}

export default ArrowDownIcon;