import React from 'react';
import PriceFormatter from './../../formatter.js'
const Price =  ({oldPrice, price}) => {

    return (
        <div>
            {oldPrice ? <div className="c-price-tag c-price-tag--old-price">{PriceFormatter(oldPrice)}<span className="u-font-size-tiny">RSD</span></div> : null }

            <div className={oldPrice ? 'c-price-tag c-price-tag--discount' : 'c-price-tag'}>{PriceFormatter(price)}<span className="u-font-size-tiny">RSD</span></div>
        </div>
    )
}

export default Price;