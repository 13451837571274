import React from 'react';

const SiteLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 228.005 31.991" enableBackground="new 0 0 228.005 31.991" fill="#ffffff">
            <g>
                <g>
                    <path d="M10.226,22.956h29.841v-5.393H10.226V22.956z M87.373,0H4.884C2.187,0,0,2.168,0,4.843v22.304
                        c0,2.675,2.187,4.843,4.884,4.843h82.489c2.697,0,4.884-2.168,4.884-4.843V4.843C92.257,2.168,90.071,0,87.373,0z M44.343,26.091
                        H8.376c-1.34,0-2.427-1.078-2.427-2.407v-9.256h34.117V9.035H5.949V5.901h35.967c1.34,0,2.427,1.077,2.427,2.407V26.091z
                        M86.308,9.035h-33.32v5.393h33.32v9.256c0,1.329-1.086,2.407-2.427,2.407H48.712v-3.135h33.32v-5.393h-33.32V8.307
                        c0-1.329,1.087-2.407,2.427-2.407h35.169V9.035z M113.502,13.316c-0.606-0.649-1.349-1.15-2.231-1.505
                        c-0.883-0.354-1.879-0.531-2.99-0.531c-0.912,0-1.795,0.182-2.647,0.546c-0.853,0.364-1.607,0.875-2.261,1.534
                        c-0.654,0.659-1.175,1.451-1.562,2.375c-0.386,0.925-0.58,1.957-0.58,3.098c0,1.22,0.203,2.306,0.609,3.26
                        c0.407,0.954,0.947,1.76,1.622,2.419c0.674,0.659,1.458,1.16,2.349,1.505c0.893,0.344,1.835,0.516,2.827,0.516
                        c0.872,0,1.665-0.117,2.379-0.354c0.714-0.236,1.349-0.56,1.904-0.974c0.555-0.413,1.021-0.895,1.398-1.446
                        c0.376-0.55,0.664-1.14,0.863-1.77l-2.915-0.915c-0.238,0.708-0.64,1.303-1.205,1.785c-0.565,0.482-1.373,0.722-2.424,0.722
                        c-0.536,0-1.037-0.093-1.503-0.28c-0.466-0.187-0.877-0.447-1.235-0.781c-0.357-0.334-0.644-0.728-0.863-1.181
                        c-0.218-0.451-0.337-0.954-0.357-1.505h10.59c0.02-0.039,0.039-0.162,0.06-0.369c0.019-0.206,0.03-0.447,0.03-0.722
                        c0-1.14-0.159-2.169-0.476-3.083C114.567,14.747,114.106,13.965,113.502,13.316z M104.771,17.329
                        c0.02-0.374,0.119-0.753,0.297-1.136c0.179-0.384,0.417-0.727,0.714-1.033c0.298-0.305,0.659-0.55,1.086-0.738
                        c0.426-0.187,0.908-0.28,1.443-0.28c0.595,0,1.11,0.089,1.547,0.266c0.436,0.177,0.803,0.413,1.1,0.708
                        c0.298,0.295,0.521,0.635,0.67,1.018c0.149,0.383,0.233,0.781,0.253,1.195H104.771z M117.137,26.091h3.451V4.732h-3.451V26.091z
                        M134.649,13.316c-0.606-0.649-1.349-1.15-2.231-1.505c-0.883-0.354-1.88-0.531-2.99-0.531c-0.912,0-1.795,0.182-2.647,0.546
                        c-0.853,0.364-1.606,0.875-2.261,1.534c-0.654,0.659-1.175,1.451-1.562,2.375c-0.386,0.925-0.58,1.957-0.58,3.098
                        c0,1.22,0.203,2.306,0.609,3.26c0.407,0.954,0.947,1.76,1.622,2.419c0.674,0.659,1.458,1.16,2.349,1.505
                        c0.892,0.344,1.834,0.516,2.827,0.516c0.872,0,1.665-0.117,2.379-0.354c0.714-0.236,1.349-0.56,1.904-0.974
                        c0.555-0.413,1.021-0.895,1.398-1.446c0.376-0.55,0.664-1.14,0.863-1.77l-2.915-0.915c-0.238,0.708-0.64,1.303-1.205,1.785
                        c-0.565,0.482-1.374,0.722-2.424,0.722c-0.536,0-1.037-0.093-1.503-0.28c-0.466-0.187-0.877-0.447-1.235-0.781
                        c-0.357-0.334-0.644-0.728-0.863-1.181c-0.218-0.451-0.337-0.954-0.357-1.505h10.59c0.02-0.039,0.039-0.162,0.06-0.369
                        c0.019-0.206,0.03-0.447,0.03-0.722c0-1.14-0.159-2.169-0.476-3.083C135.714,14.747,135.253,13.965,134.649,13.316z
                        M125.918,17.329c0.019-0.374,0.119-0.753,0.297-1.136c0.179-0.384,0.417-0.727,0.714-1.033c0.298-0.305,0.659-0.55,1.086-0.738
                        c0.426-0.187,0.908-0.28,1.443-0.28c0.595,0,1.11,0.089,1.547,0.266c0.436,0.177,0.803,0.413,1.1,0.708
                        c0.298,0.295,0.521,0.635,0.67,1.018c0.149,0.383,0.233,0.781,0.253,1.195H125.918z M151.017,12.849
                        c0.412-0.421,0.111-1.126-0.48-1.126h-3l-5.563,5.812V4.732h-3.421v21.359h3.421v-4.013l1.785-1.858l4.254,5.871h4.225
                        l-6.069-8.29L151.017,12.849z M160.21,23.229c-0.636,0-1.101-0.143-1.398-0.428c-0.298-0.285-0.447-0.742-0.447-1.372v-6.667
                        h3.005v-3.039h-3.005V7.327h-3.124v2.154c0,0.63-0.178,1.16-0.535,1.593c-0.357,0.433-0.922,0.649-1.696,0.649h-0.744v3.039h2.678
                        v7.228c0,1.357,0.386,2.409,1.16,3.156c0.773,0.748,1.835,1.121,3.183,1.121c0.634,0,1.12-0.044,1.458-0.133
                        c0.337-0.088,0.555-0.152,0.654-0.192v-2.832c-0.079,0.02-0.238,0.044-0.476,0.073C160.686,23.214,160.448,23.229,160.21,23.229z
                        M171.455,11.547c-0.952,0-1.805,0.217-2.559,0.649c-0.754,0.433-1.299,1.043-1.636,1.829v-2.301h-3.361v14.368h3.451v-6.845
                        c0-1.357,0.312-2.399,0.938-3.127c0.625-0.727,1.611-1.092,2.96-1.092c0.357,0,0.734,0.03,1.131,0.089v-3.481
                        c-0.08-0.019-0.199-0.04-0.357-0.059C171.861,11.557,171.673,11.547,171.455,11.547z M186.12,13.45
                        c-0.674-0.679-1.468-1.21-2.38-1.594c-0.912-0.384-1.914-0.575-3.005-0.575c-1.071,0-2.067,0.192-2.989,0.575
                        c-0.922,0.383-1.715,0.915-2.38,1.594c-0.665,0.678-1.185,1.48-1.561,2.404c-0.377,0.925-0.565,1.938-0.565,3.039
                        c0,1.101,0.188,2.119,0.565,3.053c0.376,0.934,0.897,1.741,1.561,2.419c0.665,0.679,1.458,1.209,2.38,1.593
                        c0.922,0.383,1.919,0.575,2.989,0.575c1.091,0,2.092-0.192,3.005-0.575c0.912-0.384,1.706-0.914,2.38-1.593
                        c0.674-0.678,1.2-1.485,1.577-2.419c0.376-0.934,0.565-1.952,0.565-3.053c0-1.101-0.189-2.114-0.565-3.039
                        C187.32,14.93,186.794,14.128,186.12,13.45z M184.454,20.869c-0.218,0.57-0.516,1.047-0.892,1.431
                        c-0.377,0.383-0.809,0.673-1.294,0.87c-0.487,0.197-0.996,0.295-1.532,0.295c-0.535,0-1.046-0.099-1.532-0.295
                        c-0.486-0.197-0.912-0.487-1.279-0.87c-0.367-0.384-0.665-0.861-0.892-1.431c-0.228-0.57-0.342-1.229-0.342-1.977
                        c0-0.747,0.114-1.402,0.342-1.962c0.228-0.56,0.525-1.033,0.892-1.416c0.367-0.384,0.793-0.668,1.279-0.856
                        c0.486-0.187,0.996-0.28,1.532-0.28c0.536,0,1.046,0.094,1.532,0.28c0.486,0.187,0.917,0.472,1.294,0.856
                        c0.376,0.383,0.674,0.856,0.892,1.416c0.219,0.56,0.328,1.214,0.328,1.962C184.782,19.64,184.673,20.298,184.454,20.869z
                        M202.436,12.977c-0.427-0.521-0.967-0.929-1.621-1.225c-0.655-0.294-1.428-0.442-2.32-0.442c-0.813,0-1.612,0.182-2.395,0.546
                        c-0.784,0.364-1.412,0.959-1.889,1.785v-1.918h-3.361v14.368h3.451v-8.29c0-0.944,0.253-1.745,0.759-2.404
                        c0.505-0.659,1.254-0.988,2.245-0.988c1.091,0,1.859,0.31,2.306,0.929c0.446,0.619,0.669,1.372,0.669,2.257v8.496h3.451v-9.087
                        c0-0.786-0.11-1.524-0.327-2.213C203.185,14.104,202.863,13.498,202.436,12.977z M208.877,4.436c-0.615,0-1.141,0.217-1.577,0.649
                        c-0.436,0.433-0.654,0.965-0.654,1.593c0,0.63,0.218,1.156,0.654,1.579c0.436,0.423,0.962,0.634,1.577,0.634
                        c0.634,0,1.17-0.211,1.607-0.634c0.436-0.423,0.654-0.949,0.654-1.579c0-0.629-0.219-1.16-0.654-1.593
                        C210.047,4.653,209.511,4.436,208.877,4.436z M207.181,26.091h3.421V11.723h-3.421V26.091z M221.936,17.801l4.847-4.952
                        c0.412-0.421,0.111-1.126-0.48-1.126h-2.999l-5.563,5.812V4.732h-3.421v21.359h3.421v-4.013l1.785-1.858l4.254,5.871h4.225
                        L221.936,17.801z"/>
                </g>
            </g>
        </svg>
    );
}

export default SiteLogo;