import React, {Component} from 'react';
import SiteLogo from './../assets/icons/SiteLogo';
import PhoneIcon from './../assets/icons/PhoneIcon';
import Drawer from './Drawer';

export default class PageHeader extends Component {

    constructor() {
        super();
        this.state = {
            drawerOpen: false,
            drawerType: 'CONTACT',
            menuOpen: false
        }

        this.onCloseDrawer = this.onCloseDrawer.bind(this);
        this.onOpenDrawer = this.onOpenDrawer.bind(this);
        this.onClickLogo = this.onClickLogo.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.openMenu = this.openMenu.bind(this);
    }

    onClickLogo(evt) {
        console.log(evt);
        document.body.scrollIntoView({
            behavior: "smooth", block: "start"
        });

        if(this.state.menuOpen) {
            this.closeMenu();
        }
    }

    onCloseDrawer(evt) {
        document.documentElement.removeAttribute('style');
        this.setState({drawerOpen: false})
        evt.currentTarget.nextSibling.scrollTop = 0;

    }

    onOpenDrawer() {
        this.setState({drawerOpen: true}); 
        document.documentElement.style.overflow='hidden';
        this.closeMenu();
    }

    onContentOffset() {
        const DOMHeader = document.querySelector('.js-page-header');
        document.body.style.paddingTop = DOMHeader.offsetHeight + 'px';
    }

    componentDidMount() {
        this.onContentOffset();
    }

    onToggleMenu() {

        if(this.state.menuOpen) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    }

    closeMenu() {
        this.setState({menuOpen: false});
    }

    openMenu() {
        this.setState({menuOpen: true})
    }

    goToLink(event) {
        event.preventDefault();
        const scrollToAncor = document.getElementById(event.currentTarget.dataset.scrollTo);
        if (scrollToAncor) scrollToAncor.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        this.closeMenu();
    }

    render() {
        const  {drawerOpen, drawerType, menuOpen} = this.state
        return (
            <section>
                <Drawer drawerData={null} drawerOpen={drawerOpen} onCloseDrawer={()=>this.onCloseDrawer} drawerType={drawerType}></Drawer>
                <header className="c-header js-page-header">
                    <nav className={menuOpen ? "c-nav is-open" : "c-nav"}>
                        <a href="javascript:;" data-scroll-to="TOP" onClick={event => this.goToLink(event)}>Prodaja</a>
                        <a href="javascript:;" data-scroll-to="SERVICE" onClick={event => this.goToLink(event)}>Servis</a>
                        <a href="javascript:;" data-scroll-to="COPY" onClick={event => this.goToLink(event)}>Kopirnica</a>
                        <a href="javascript:;" data-scroll-to="PRINTERS" onClick={event => this.goToLink(event)}>Polovni štampači</a>
                        <a href="javascript:;" onClick={()=> this.onOpenDrawer()}>Kontakt</a>
                    </nav>
                    <div className="o-grid o-grid--justify o-grid--middle">
                        <div className="o-grid__cell u-1/2">
                            <div className="c-site-logo">
                                <a href="javascript:;" onClick={event => this.onClickLogo()}>
                                    <SiteLogo/>
                                </a>
                            </div>
                        </div>
                        <div className="o-grid__cell u-1/2 u-text-right">
                            <a href="tel:+38115891999" className="c-btn">
                                <span className="[ o-circle o-circle--red o-circle--medium ]">
                                    <PhoneIcon iconWidth={'14px'} iconHeight={'14px'}/>
                                </span>
                                <span className="u-hide@tablet u-margin-left-small">
                                    015 891 999
                                </span>
                            </a>
                            
                            <div onClick={()=> this.onToggleMenu()} 
                                 className={menuOpen ? "c-menu-btn is-open" : "c-menu-btn"}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </header>
            </section>
        )
    }
}