import React from 'react';

const SliderArrow = (props)=> {
    return (
        <svg className="o-icon" style={{width: props.iconWidth, height: props.iconHeight}}  xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 19.993 14.001" enableBackground="new 0 0 19.993 14.001" >
            <path fillRule="evenodd" clipRule="evenodd" d="M19.695,6.217l-5.911-5.911c-0.408-0.408-1.07-0.408-1.478,0
                c-0.408,0.408-0.408,1.069,0,1.477l4.217,4.217H1c-0.552,0-1,0.448-1,1c0,0.552,0.448,1,1,1h15.523l-4.217,4.217
                c-0.408,0.408-0.408,1.07,0,1.478c0.408,0.408,1.07,0.408,1.478,0l5.911-5.911c0.216-0.216,0.31-0.501,0.297-0.784
                C20.004,6.718,19.91,6.432,19.695,6.217z"/>
        </svg>

    );
}

export default SliderArrow;


