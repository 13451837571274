import React from 'react';

const SiteLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 235 33.247" enableBackground="new 0 0 235 33.247">
        <path fill="#EC2227" d="M0,29.964V3.284C0,1.47,1.47,0,3.284,0h88.521c1.813,0,3.284,1.47,3.284,3.284v26.68
            c0,1.813-1.47,3.284-3.284,3.284H3.284C1.47,33.247,0,31.777,0,29.964z"/>
        <g>
            <path fill="#FFFFFF" d="M6.132,6.132V9.39h35.164v5.605H6.132v9.619c0,1.381,1.12,2.501,2.501,2.501h37.07V8.633
                c0-1.381-1.12-2.501-2.501-2.501H6.132z M41.296,23.858H10.54v-5.605h30.756V23.858z"/>
            <path fill="#FFFFFF" d="M88.956,9.39V6.132H52.708c-1.381,0-2.501,1.12-2.501,2.501v9.619h34.342v5.605H50.206v3.258h36.248
                c1.381,0,2.501-1.12,2.501-2.501v-9.619H54.614V9.39H88.956z"/>
        </g>
        <g>
            <g>
                <path fill="#211D70" d="M118.716,22.854c-0.205,0.654-0.502,1.267-0.889,1.839c-0.388,0.573-0.869,1.073-1.441,1.502
                    c-0.573,0.43-1.227,0.767-1.963,1.012c-0.736,0.246-1.553,0.368-2.452,0.368c-1.023,0-1.993-0.179-2.913-0.536
                    c-0.919-0.358-1.727-0.879-2.422-1.564c-0.695-0.685-1.252-1.523-1.672-2.514c-0.419-0.991-0.628-2.121-0.628-3.388
                    c0-1.186,0.199-2.258,0.597-3.219c0.399-0.96,0.936-1.783,1.61-2.468c0.674-0.685,1.451-1.216,2.33-1.594
                    c0.878-0.378,1.788-0.567,2.728-0.567c1.145,0,2.172,0.184,3.082,0.552c0.909,0.368,1.675,0.889,2.3,1.564
                    c0.623,0.674,1.098,1.487,1.425,2.438c0.327,0.95,0.491,2.019,0.491,3.204c0,0.287-0.011,0.537-0.031,0.751
                    c-0.021,0.215-0.041,0.343-0.061,0.383h-10.915c0.02,0.573,0.143,1.094,0.368,1.564c0.225,0.471,0.522,0.88,0.889,1.227
                    c0.368,0.347,0.792,0.618,1.273,0.812c0.48,0.195,0.996,0.291,1.549,0.291c1.083,0,1.916-0.25,2.499-0.751
                    c0.582-0.501,0.996-1.119,1.242-1.855L118.716,22.854z M115.313,18.01c-0.02-0.43-0.107-0.844-0.26-1.242
                    c-0.153-0.398-0.383-0.752-0.69-1.058c-0.306-0.307-0.685-0.552-1.134-0.736c-0.45-0.184-0.981-0.276-1.594-0.276
                    c-0.552,0-1.048,0.097-1.487,0.291c-0.439,0.195-0.812,0.45-1.119,0.767c-0.306,0.317-0.552,0.674-0.736,1.073
                    c-0.183,0.398-0.286,0.792-0.306,1.18H115.313z"/>
                <path fill="#211D70" d="M120.731,27.116V4.917h3.556v22.198H120.731z"/>
                <path fill="#211D70" d="M140.512,22.854c-0.205,0.654-0.502,1.267-0.889,1.839c-0.389,0.573-0.869,1.073-1.441,1.502
                    c-0.573,0.43-1.227,0.767-1.963,1.012c-0.736,0.246-1.553,0.368-2.452,0.368c-1.023,0-1.993-0.179-2.913-0.536
                    c-0.919-0.358-1.727-0.879-2.422-1.564c-0.695-0.685-1.252-1.523-1.672-2.514c-0.419-0.991-0.628-2.121-0.628-3.388
                    c0-1.186,0.199-2.258,0.597-3.219c0.399-0.96,0.936-1.783,1.61-2.468s1.451-1.216,2.33-1.594c0.878-0.378,1.788-0.567,2.728-0.567
                    c1.145,0,2.172,0.184,3.082,0.552c0.909,0.368,1.675,0.889,2.3,1.564c0.623,0.674,1.098,1.487,1.425,2.438
                    c0.327,0.95,0.491,2.019,0.491,3.204c0,0.287-0.011,0.537-0.031,0.751c-0.021,0.215-0.041,0.343-0.061,0.383h-10.915
                    c0.02,0.573,0.143,1.094,0.368,1.564c0.225,0.471,0.522,0.88,0.889,1.227c0.368,0.347,0.792,0.618,1.273,0.812
                    c0.48,0.195,0.996,0.291,1.549,0.291c1.083,0,1.916-0.25,2.499-0.751c0.582-0.501,0.996-1.119,1.242-1.855L140.512,22.854z
                    M137.108,18.01c-0.02-0.43-0.107-0.844-0.26-1.242c-0.153-0.398-0.383-0.752-0.69-1.058c-0.306-0.307-0.685-0.552-1.134-0.736
                    c-0.45-0.184-0.981-0.276-1.594-0.276c-0.552,0-1.048,0.097-1.487,0.291c-0.439,0.195-0.812,0.45-1.119,0.767
                    c-0.306,0.317-0.552,0.674-0.736,1.073c-0.183,0.398-0.286,0.792-0.306,1.18H137.108z"/>
                <path fill="#211D70" d="M150.654,18.5l6.255,8.616h-4.354l-4.384-6.101l-1.84,1.931v4.17h-3.526V4.917h3.526v13.307l5.734-6.041
                    h3.091c0.609,0,0.919,0.733,0.495,1.17L150.654,18.5z"/>
                <path fill="#211D70" d="M163.224,12.184h3.097v3.158h-3.097v6.929c0,0.655,0.153,1.13,0.46,1.426
                    c0.306,0.296,0.786,0.445,1.441,0.445c0.245,0,0.49-0.016,0.736-0.046c0.245-0.031,0.409-0.055,0.49-0.076v2.943
                    c-0.103,0.041-0.327,0.108-0.674,0.199c-0.348,0.092-0.849,0.138-1.502,0.138c-1.39,0-2.484-0.388-3.281-1.165
                    c-0.797-0.776-1.195-1.87-1.195-3.28v-7.512h-2.76v-3.158h0.767c0.797,0,1.38-0.225,1.748-0.674
                    c0.368-0.449,0.552-1.001,0.552-1.655V7.615h3.219V12.184z"/>
                <path fill="#211D70" d="M177.666,15.71c-0.409-0.061-0.797-0.092-1.165-0.092c-1.39,0-2.407,0.379-3.05,1.135
                    c-0.644,0.756-0.966,1.839-0.966,3.249v7.113h-3.556V12.184h3.464v2.392c0.347-0.817,0.909-1.451,1.686-1.901
                    c0.777-0.449,1.656-0.674,2.637-0.674c0.225,0,0.418,0.01,0.582,0.031c0.163,0.02,0.286,0.041,0.368,0.061V15.71z"/>
                <path fill="#211D70" d="M186.281,11.724c1.124,0,2.157,0.199,3.097,0.598c0.94,0.398,1.758,0.951,2.453,1.656
                    c0.695,0.705,1.237,1.538,1.625,2.499c0.388,0.961,0.582,2.014,0.582,3.158s-0.195,2.202-0.582,3.173
                    c-0.389,0.971-0.93,1.809-1.625,2.514c-0.695,0.705-1.513,1.257-2.453,1.656c-0.94,0.398-1.972,0.597-3.097,0.597
                    c-1.103,0-2.13-0.199-3.081-0.597c-0.951-0.399-1.768-0.951-2.453-1.656s-1.222-1.544-1.609-2.514
                    c-0.388-0.971-0.582-2.029-0.582-3.173s0.194-2.197,0.582-3.158c0.388-0.96,0.924-1.794,1.609-2.499
                    c0.685-0.705,1.502-1.258,2.453-1.656C184.151,11.923,185.178,11.724,186.281,11.724z M186.281,24.387
                    c0.552,0,1.078-0.103,1.579-0.307c0.501-0.204,0.945-0.506,1.334-0.904c0.388-0.399,0.695-0.895,0.919-1.487
                    c0.225-0.593,0.338-1.277,0.338-2.054s-0.112-1.457-0.338-2.039c-0.225-0.582-0.531-1.073-0.919-1.472
                    c-0.389-0.399-0.833-0.695-1.334-0.889c-0.502-0.194-1.027-0.291-1.579-0.291c-0.552,0-1.078,0.097-1.579,0.291
                    c-0.501,0.195-0.94,0.49-1.318,0.889c-0.379,0.398-0.685,0.889-0.92,1.472c-0.235,0.582-0.353,1.262-0.353,2.039
                    s0.118,1.461,0.353,2.054c0.235,0.593,0.541,1.088,0.92,1.487c0.378,0.398,0.817,0.7,1.318,0.904
                    C185.203,24.285,185.729,24.387,186.281,24.387z"/>
                <path fill="#211D70" d="M200.261,27.116h-3.556V12.184h3.464v1.993c0.491-0.859,1.139-1.477,1.947-1.855
                    c0.808-0.378,1.63-0.567,2.469-0.567c0.92,0,1.716,0.153,2.392,0.46c0.674,0.307,1.231,0.731,1.671,1.273
                    c0.439,0.542,0.771,1.171,0.996,1.886c0.225,0.716,0.338,1.482,0.338,2.3v9.444h-3.557v-8.83c0-0.92-0.23-1.702-0.689-2.346
                    c-0.46-0.644-1.252-0.966-2.377-0.966c-1.022,0-1.794,0.342-2.315,1.027c-0.521,0.685-0.782,1.518-0.782,2.499V27.116z"/>
                <path fill="#211D70" d="M212.986,6.941c0-0.653,0.225-1.206,0.674-1.656c0.449-0.449,0.991-0.674,1.625-0.674
                    c0.653,0,1.206,0.225,1.656,0.674c0.449,0.45,0.674,1.002,0.674,1.656c0,0.655-0.225,1.201-0.674,1.641
                    c-0.45,0.439-1.002,0.659-1.656,0.659c-0.634,0-1.176-0.219-1.625-0.659C213.211,8.142,212.986,7.596,212.986,6.941z
                    M213.538,27.116V12.184h3.526v14.932H213.538z"/>
                <path fill="#211D70" d="M228.745,18.5L235,27.116h-4.354l-4.384-6.101l-1.84,1.931v4.17h-3.526V4.917h3.526v13.307l5.734-6.041
                    h3.091c0.609,0,0.919,0.733,0.495,1.17L228.745,18.5z"/>
            </g>
        </g>
        </svg>
    );
}

export default SiteLogo;