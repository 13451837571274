import React, { Component } from 'react';
import PageHeader from './components/Header';
import PageFooter from './components/Footer';
import HeroSlider from './components/HeroSlider';
import ProductsList from './components/products/ProductsList';

import CopyImage from './assets/images/najpovoljnije-kopiranje.jpg';
import ServiceImage from './assets/images/servis.jpg';
import PrinterImage from './assets/images/polovni-stampacix2.png';
import ArrowDownIcon from './assets/icons/ArrowDownIcon';
import PhoneIcon from './assets/icons/PhoneIcon';
import Connection from './connection.json'

class App extends Component {
  
  state = {
    screenWidth: 0
  }

  componentDidMount() {
    this.setState({screenWidth: this.getScreenSize()});
  }

  getScreenSize() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  };

  render() {
    return (
      <div className="o-page">
        <PageHeader></PageHeader>
        
        <main className="o-page__content">
          { this.state.screenWidth > 740 ? <HeroSlider></HeroSlider> : null }

          <section className="c-section" id="TOP">
            <div className="o-wrapper o-wrapper--large">
              <div className={ this.state.screenWidth > 740 ? "[ o-grid o-grid--between o-grid--bottom ]": "[ o-grid o-grid--sm ]"}>
                <div className="o-grid__cell u-1/2@tablet">
                  <h2 className="u-margin-none u-text-center@tablet">Izdvajamo iz ponude</h2>
                </div>
                <div className="o-grid__cell u-1/2@tablet">
                  <p className="u-font-size-small u-margin-none u-text-right u-text-center@tablet">Za više informacija pozovite <a className="u-color-base-text" href="tel:+38115 891 999">015 891 999</a></p>
                </div>
              </div>

              <ProductsList onClickProduct={product => this.onClickProduct(product)} 
                            screenWidth={this.state.screenWidth}></ProductsList>

            </div>
          </section>
          <section className="c-section" id="COPY" style={{backgroundImage: `url(${CopyImage})`}}>
            <div className="o-wrapper c-section__content">
              <div className="[ o-grid o-grid--start ]">
                <div className="o-grid__cell u-1/3@tablet u-text-center@tablet">
                  <h3 className="u-color-white">Najpovoljnije kopiranje i štampanje u gradu</h3>
                  <p className="u-color-white u-margin-bottom-large">Kopiranje crno–belo i u boji. Slanje skeniranih dokumenata putem e-maila. Štampanje sa memorijskih uređaja kao i sa našeg e-maila <a className="u-color-white" href="mailto:aselektronik015@gmail.com">aselektronik015@gmail.com</a>.</p>
                  <a className="c-btn c-btn--primary c-btn--red c-btn--icon-right" download href={Connection.PriningPriceList}>
                      Preuzmite cenovnik 
                      <span className="c-btn__icon">
                          <ArrowDownIcon iconWidth={'10px'} iconHeight={'12px'}/>
                      </span>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="c-section" id="SERVICE" style={{backgroundImage: `url(${ServiceImage})`}}>
            <div className="[ o-wrapper o-wrapper--small ] c-section__content u-text-center">
              { this.state.screenWidth > 740 ?  <h1>Servis</h1> : <h2>Servis</h2> }
              <p className="u-margin-none">Servis računara, fiskalnih kasa, štampača, reciklaža tonera, konfigurisanje računarskih mreža i povezivanje istih, postavljanje rek ormana. Po vašem pozivu dolazimo na lice mesta i otklanjamo kvarove na vašim uređajima.!</p>
              <p>Pozovite nas!</p>
              <a className="c-btn c-btn--primary c-btn--blue c-btn--icon-left" href="tel:+38115891999">
                <span className="u-font-size-normal">015891999</span>
                <span className="c-btn__icon">
                    <PhoneIcon iconWidth={'14px'} iconHeight={'14px'}/>
                </span>
              </a>
            </div>
          </section>

          <section className="c-section" id="PRINTERS">
            <div className="o-wrapper">
              <div className="[ o-grid o-grid--middle ] u-text-center@tablet">
                <div className="o-grid__cell u-1/2@tablet">
                  <h2>Polovni štampači</h2>
                  <p className="u-margin-bottom-large">Veliki izbor polovnih laserskih i ink-jet štampča poznatih svetskih brendova. Trenutnu ponudu mozete preuzeti klikom na dugme ispod.</p>
                  <a className="c-btn c-btn--primary c-btn--red c-btn--icon-right" download href={Connection.PrinterPriceList}>
                      Preuzmite cenovnik 
                      <span className="c-btn__icon">
                          <ArrowDownIcon iconWidth={'10px'} iconHeight={'12px'}/>
                      </span>
                  </a>
                </div>
                <div className="o-grid__cell u-1/2@tablet">
                  <img className="c-section__img" alt='Polovni Štampači' src={PrinterImage}/>
                </div>
              </div>
            </div>
          </section>
        </main>
        <PageFooter screenWidth={this.state.screenWidth}></PageFooter>
      </div>
    );
  }
}

export default App;
