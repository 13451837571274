import React from 'react';
import Price from './Price'

const Product = ({product, onClickProduct}) => {

    return (
        <div className="o-grid__cell u-1/4@desktop u-1/3@tablet u-1/2@mobile">

            <div className="c-box c-products__item" 
                onClick={()=> onClickProduct(product)}>

                <div className="c-products__content">
                    <img alt={product.name} title={product.name} src={product.imageUrl}/>
                </div>

                <footer>
                    <p className="u-margin-bottom-small">{product.name}</p>
                    <Price oldPrice={product.oldPrice} price={product.price}/>
                </footer>
                
            </div>
        </div>
    );
}

export default Product;
