import React, {Component} from 'react';
import FooterLogo from './../assets/icons/FooterLogo';
import ArrowDownIcon from './../assets/icons/ArrowDownIcon';



class PageFooter extends Component {
    constructor(props) {
        super(props);
    }

    scrollToTop() {
        document.body.scrollIntoView({
            behavior: "smooth", block: "start"
        });
    }

    render() {
        return (
            <footer className="o-page__footer c-footer">
                <div className="o-grid o-grid--middle">
                    <div className="o-grid__cell [ u-1/3@tablet u-text-left u-text-center@tablet ]">
                        <div className="c-footer__logo">
                            <FooterLogo></FooterLogo>
                        </div>
                        <p className="u-color-white u-font-size-xsmall u-margin-none u-font-weight-medium">Servis i prodaja računara, <br/> fiskalnih uređaja i biro opreme</p>
                    </div>
                    <div className="o-grid__cell [ u-1/3@tablet u-text-center ]" style={this.props.screenWidth > 740 ? {}:{order: '1'}}>
                        <dl className="u-margin-none">
                            <dt>
                                <button onClick={(evt)=>{this.scrollToTop()}} className="[ c-btn c-btn--ghost c-btn--white ] o-circle c-footer__link">
                                    <ArrowDownIcon iconWidth={'10px'} iconHeight={'12px'} iconFill={'#ffffff'}/>
                                </button>
                            </dt>
                            <dd className="u-margin-none"><span className="u-color-white u-font-size-tiny u-font-weight-medium">NAZAD NA VRH</span></dd>
                            </dl>
                    </div>
                    <div className="o-grid__cell [ u-1/3@tablet u-text-right u-text-center@tablet ]">
                        <p className="u-color-white u-font-size-xsmall u-margin-none u-font-weight-medium">
                            Loznica, Pop Lukina 4 <br/>
                            tel.(015)891-999 (015)875-125 <br/>
                            e-mail: <a className="u-color-white" href="mailto:aselektronik@mts.rs">aselektronik@mts.rs</a>
                        </p>
                    </div>
                </div>
            </footer>
        );
    }

}


export default PageFooter;