import React from 'react';
import CloseIcon from './../assets/icons/CloseIcon';
import Price from './products/Price';
import PhoneIcon from './../assets/icons/PhoneIcon';
import GoogleMapImg from './../assets/images/googleMap.png'

const Drawer = ({drawerOpen, drawerData, onCloseDrawer, drawerType}) => {

    if(drawerType === 'DETAILS') {
        
        const specifications = drawerData.specifications !== undefined ? drawerData.specifications.map((spec, index) => {
            return (
                <tr key={index}>
                    <td>{spec.name}</td>
                    <td>{spec.value}</td>
                </tr>
            ) 
            
        }):[];

        return (
            <div className={drawerOpen ? "c-drawer c-drawer--open" : "c-drawer"}>
                <button className="c-btn c-drawer__close" onClick={onCloseDrawer()}>
                    <CloseIcon iconHeight={'15px'} iconWidth={'15px'}></CloseIcon>
                </button>
                <section className="c-drawer__content s-content">
                    
                    <figure>
                        <img src={drawerData.imageUrl} title='Laptop' alt='Lenovo laptop' />
                    </figure>
                    <h2>{drawerData.name}</h2>
                    <div className="u-margin-bottom">
                        <Price oldPrice={drawerData.oldPrice} price={drawerData.price}/>
                    </div>
                    {
                        drawerData.description ? 
                            <p className="u-margin-bottom-large u-font-size-small">{drawerData.description}</p>
                            : ""
                    }
                    { 
                        specifications.length > 0 ? 
                        <table className="u-margin-bottom-large">
                            <thead>
                                <tr>
                                    <th colSpan="2">Specifikacije</th>
                                </tr>
                            </thead>
                            <tbody>
                                {specifications}
                            </tbody>
                        </table> : null
                    }
    
                    <p className="u-font-size-small">Za više informacija pozovite:</p>
                    <a className="c-btn c-btn--primary c-btn--blue c-btn--icon-left" href="tel:+38115891999">
                        <span className="u-font-size-normal">015891999</span>
                        <span className="c-btn__icon">
                            <PhoneIcon iconWidth={'14px'} iconHeight={'14px'}/>
                        </span>
                    </a>
                    
                </section>
            </div>
        );
    } else {

        return (
            <div className={drawerOpen ? "c-drawer c-drawer--open" : "c-drawer"}>
                <button className="c-btn c-drawer__close" onClick={onCloseDrawer()}>
                    <CloseIcon iconHeight={'15px'} iconWidth={'15px'}></CloseIcon>
                </button>
                <section className="c-drawer__content s-content">
                    <h2>Kontakt</h2>
                    <div className="c-drawer__map u-margin-bottom-large">
                        {/* <GoogleMap/> */}
                        <img src={GoogleMapImg}/>
                    </div>
                    <h4 className="u-margin-bottom-none">AS Elektronik</h4>
                    <p className="u-font-size-normal">
                        <a href='https://www.google.com/maps/place/As+Elektronik/@44.5309863,19.22005,17z/data=!4m5!3m4!1s0x0:0x513814464efda6c2!8m2!3d44.5307741!4d19.2223151' 
                        target='_blank' 
                        className="u-font-size-normal">Loznica, Pop Lukina 4</a>
                    </p>
                    
                    <p className="u-font-size-normal u-margin-bottom-none">
                        <span className="u-margin-right-tiny">tel.</span>
                        <a className="u-margin-right-tiny u-color-base-text" 
                           href="tel:+38115891999">(015)891-999</a>
                        <a className="u-color-base-text" 
                           href="tel:+38115875125">(015)875-125</a>
                    </p>
                    <p className="u-font-size-normal">
                        e-mail: <a href="mailto:aselektronik@mts.rs" target="_top">aselektronik@mts.rs</a>
                    </p>
                    <h4 className="u-margin-bottom-none">Radno vreme</h4>
                    <p className="u-font-size-normal u-margin-bottom-none">Radnim danima od 7 do 20h</p>
                    <p className="u-font-size-normal">Subotom od 8 do 16h</p>
                </section>
            </div>
        )
    }
    
}

export default Drawer;
