import React from 'react';

const PhoneIcon = (props) => {
    return (
        <svg className="o-icon" style={{width: props.iconWidth, height: props.iconHeight}} xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 14.999 13.999" enableBackground="new 0 0 14.999 13.999">
        <path d="M2.365,0.013C2.261-0.007,2.137-0.007,2.054,0.032C0.933,0.44,0.228,1.935,0.062,2.964
            c-0.518,3.339,2.303,6.232,4.896,8.135c2.303,1.689,6.721,4.465,9.335,1.786c0.332-0.33,0.726-0.815,0.705-1.301
            c-0.062-0.815-0.871-1.398-1.494-1.844c-0.477-0.33-1.473-1.242-2.095-1.223c-0.56,0.019-0.913,0.563-1.265,0.893L9.522,9.992
            C9.418,10.089,8.09,9.273,7.945,9.176C7.406,8.846,6.887,8.477,6.431,8.07c-0.456-0.408-0.871-0.854-1.224-1.32
            c-0.104-0.136-0.954-1.32-0.871-1.417c0,0,0.726-0.738,0.933-1.029c0.436-0.621,0.768-1.107,0.27-1.845
            C5.352,2.187,5.124,1.974,4.875,1.741C4.439,1.352,4.004,0.945,3.506,0.615C3.236,0.42,2.78,0.071,2.365,0.013z"/>
        </svg>

    );
}

export default PhoneIcon;