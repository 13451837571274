import React from 'react';

const CloseIcon = ({iconWidth, iconHeight}) => {
    return(
        <svg className="o-icon" style={{width: iconWidth, height: iconHeight}} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 14.728 14.728" enableBackground="new 0 0 14.728 14.728">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.778,7.364l5.657-5.657c0.391-0.391,0.391-1.024,0-1.414
            c-0.391-0.391-1.024-0.391-1.414,0L7.364,5.95L1.707,0.293c-0.391-0.391-1.024-0.391-1.414,0c-0.391,0.39-0.391,1.024,0,1.414
            L5.95,7.364l-5.657,5.657c-0.391,0.39-0.391,1.024,0,1.414c0.391,0.391,1.024,0.391,1.414,0l5.657-5.657l5.657,5.657
            c0.391,0.391,1.024,0.391,1.414,0c0.391-0.39,0.391-1.024,0-1.414L8.778,7.364z"/>
        </svg>
    );
}

export default CloseIcon;