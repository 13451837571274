import React, { Component } from 'react';
import Product from './Product';
import Drawer from './../Drawer';

import ArrowDownIcon from './../../assets/icons/ArrowDownIcon';
import ProductBadge from './../../assets/images/preuzmi-ponudu-badgex2.png'
import Connection from './../../connection.json'

export default class ProductsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedProduct: "",
            products: [],
            drawerOpen: false,
            drawerType: 'DETAILS'
        }

        this.onCloseDrawer = this.onCloseDrawer.bind(this);
        this.onClickProduct = this.onClickProduct.bind(this);
    }

    onClickProduct(product) {
        /**
         * Set html owerflow to hiddent to prevent page scorlling while drawer is open
         */
        document.documentElement.style.overflow="hidden";
        
        this.setState({
            drawerOpen: true,
            selectedProduct: product
        });
    }

    onCloseDrawer(evt) {
        document.documentElement.removeAttribute('style');
        this.setState({drawerOpen: false})
        evt.currentTarget.nextSibling.scrollTop = 0;
    }

    componentDidMount(){
        fetch(Connection.productsUrl)
          .then(response => response.json())
          .then(data => {this.setState({products: data}); })
          .catch(err=>console.log(err));
    }
 
    render() {
        const {selectedProduct, drawerOpen, drawerType, products} = this.state
       
        return (
            <div>
                <Drawer drawerData={selectedProduct} drawerOpen={drawerOpen} onCloseDrawer={()=>this.onCloseDrawer} drawerType={drawerType}></Drawer>
                
                <div className="c-products">
                    <div className={ this.props.screenWidth < 740 ? "o-grid o-grid--sm": "o-grid"}>
                        {
                            products.map((product, index) => 
                                <Product product={product} 
                                         key={index} 
                                         onClickProduct={product => this.onClickProduct(product)}/>)
                        }
                        <div className="o-grid__cell u-1/4@desktop u-1/3@tablet u-1/2@mobile">
                            <div className="c-box c-products__item c-products__item--standalone" 
                                 style={{background: `#211D70 url(${ProductBadge}) no-repeat bottom 40px right`}}>
                                <div className="c-products__content">
                                    <h3 className="u-color-white">
                                        Preuzmite
                                        našu kompletnu 
                                        ponudu
                                    </h3>
                                </div>
                                <footer>
                                    <a className="c-btn c-btn--primary c-btn--red c-btn--icon-right" 
                                       download target="_blank" 
                                       href={Connection.productsDownloadCatalog}>
                                        Preuzmi pdf
                                        <span className="c-btn__icon">
                                            <ArrowDownIcon iconWidth={'10px'} iconHeight={'12px'}/>
                                        </span>
                                    </a>
                                </footer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

